import get from 'lodash/get';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import PageBody from 'shared/components/layout/PageBody';
import LoginForm from 'shared/components/login/LoginForm';
import RegisterHeader from 'shared/components/login/RegisterHeader';
import RegisterSubscription from 'shared/components/login/RegisterSubscription';
import PageMeta from 'shared/components/PageMeta';
import { loginUser } from 'shared/redux/actions';
import { user } from 'shared/redux/selectors';
import { getText } from 'shared/utils/Text';

const getErrorMessage = createSelector(user.getError, error =>
  error
    ? get(error, 'response.message') || getText('login-message-login-failed')
    : undefined,
);

const mapStateToProps = state => {
  const isLoggedIn = user.isLoggedIn(state);
  const isLoading = user.isLoading(state);
  return {
    isLoggedIn,
    isLoading,
    email: user.getEmail(state),
    errorMessage: getErrorMessage(state),
    message: isLoggedIn
      ? getText('login-message-login-successful')
      : isLoading
      ? getText('login-logging-in')
      : undefined,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class LoginPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        redirectToReferrer: props.isLoggedIn,
        password: '',
        emailError: null,
        passwordError: null,
      };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isLoggedIn) {
        window.setTimeout(() => {
          this.setState({
            redirectToReferrer: true,
          });
        }, 500);
      }
    }

    onEmailChange = value => {
      this.setState({
        email: value,
        emailError: null,
      });
    };

    onPasswordChange = value => {
      this.setState({
        password: value,
        passwordError: null,
      });
    };

    onSubmit = e => {
      e.preventDefault();

      const { password } = this.state;
      let { email } = this.state;
      const { email: savedEmail, loginUser } = this.props;

      email = email || savedEmail;

      if (!email) {
        this.setState({
          emailError: getText('login-message-email-required'),
        });
        return;
      }

      if (!password) {
        this.setState({
          passwordError: getText('login-message-password-required'),
        });
        return;
      }

      loginUser(email, password);
    };

    render() {
      const { location, errorMessage, message, email: savedEmail } = this.props;
      const referrer = location.state?.referrer ?? { pathname: '/' };
      const { email, password, emailError, passwordError, redirectToReferrer } =
        this.state;

      if (redirectToReferrer) {
        return <Redirect to={referrer} />;
      }

      const curEmailValue = [email, savedEmail, ''].find(
        value => value != null,
      );

      return (
        <PageBody alignTo="menu" fullWidth>
          <PageMeta theme="auth" title={getText('menu-log-in')} />
          <LoginForm
            email={curEmailValue}
            password={password}
            emailError={emailError}
            passwordError={passwordError}
            submitError={errorMessage}
            message={message}
            onEmailChange={this.onEmailChange}
            onPasswordChange={this.onPasswordChange}
            onSubmit={this.onSubmit}
          />
          <RegisterHeader />
          <RegisterSubscription />
        </PageBody>
      );
    }
  },
);
