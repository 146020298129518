import { useEffect } from 'react';

import { LinkButton } from 'shared/components/button/Button';
import Editorial from 'shared/components/content/Editorial';
import JokerWizIcon from 'shared/components/icon/JokerWiz';
import ContentWrapper from 'shared/components/layout/ContentWrapper';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { getText } from 'shared/utils/Text';

import styles from './index.module.scss';

const JokerwizInfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageBody alignTo="menu">
      <PageMeta
        theme="article"
        title={getText('jokerwiz-explain-page-header')}
      />

      <ContentWrapper columnWidth="narrow">
        <header>
          <div className={styles.icon}>
            <JokerWizIcon />
          </div>

          <h1 className="article-header">
            {getText('jokerwiz-explain-page-header')}
          </h1>
        </header>

        <Editorial>
          <article>
            <div className="editorial--preamble">
              Wiz är en innovativ nyhet där du bara väljer risknivå och
              spelbelopp — resten fixar Wiz! Varje system är unikt och bakom
              rankingarna ligger landets främsta tipstjänster.
            </div>

            <p>
              Rankingarna i Wiz skapas utifrån tipsen på landets ledande
              tipstjänster{' '}
              <a
                href="https://spelvarde.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spelvärde.se
              </a>
              ,{' '}
              <a
                href="https://travogat.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Travögat.se
              </a>
              ,{' '}
              <a
                href="https://storavinster.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Storavinster.se
              </a>
              ,{' '}
              <a
                href="https://www.travrondenspel.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Travronden Spel
              </a>{' '}
              — samt unik statistik till omgången från{' '}
              <a
                href="https://travfakta.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                Travfakta.se
              </a>
              . Wiz står till din tjänst från tidig morgon dagen före varje
              V75-, V86-, GS75- och V64-omgång och ingår. Som bäst presterar Wiz
              när samtliga tipstjänster publicerat sina tips till omgången,
              vilket till ordinarie V75 är fredag 15.30, till Extra V75 15.00 på
              tävlingsdagen, till V86 15.30 på tävlingsdagen, till GS75 dagen
              före 20.30 och till V64 16.30 på tävlingsdagen. Reducering av
              systemen sker med hjälp av poängkrav. Beroende på vald insats
              varierar antalet spikar på systemen.
            </p>

            <h2>Instruktioner</h2>

            <ol>
              <li>Välj omgång.</li>
              <li>
                Välj önskad risknivå, maxpris för systemet och tryck sedan på
                &quot;Skapa system&quot;.
              </li>
              <li>Systemet är klart för inlämning!</li>
            </ol>

            <p>
              PS: Om du skulle önska göra någon förändring i systemet är det
              bara att låsa upp systemet genom att trycka på hänglåset i menyn.
            </p>

            <h2>Risknivåer</h2>

            <p>
              <strong>Låg</strong> — Förutom de tips som finns tillgängliga till
              omgången hos tipsleverantörerna så är även ATG:s insatsprocent på
              hästarna en faktor som har betydelse för den ranking som skapas.
              Tipsettor med låg risk väljs som spikar, vilket innebär att
              chansen till att få alla rätt är maximerad. Minuset är att
              sannolikheten för att systemet ska ge storvinst är lägre än vid
              övriga val.
            </p>

            <p>
              <strong>Medel</strong> — Förutom de tips som finns tillgängliga
              till omgången hos tipsleverantörerna så är även ATG:s
              insatsprocent på hästarna en faktor som har en viss betydelse för
              den ranking som skapas. Tipsettor med &quot;medelrisk&quot; väljs
              som spikar, vilket innebär att chansen till att få alla rätt är
              högre än vid val &quot;hög&quot; men samtidigt något lägre än vid
              val &quot;låg&quot;.
            </p>

            <p>
              <strong>Hög</strong> — ATG:s insatsprocent på hästarna är till
              skillnad mot risknivå låg och medel ingen faktor som räknas in för
              den ranking som skapas. Tipsettor med högre risk väljs som spikar,
              vilket innebär goda förutsättningar för att vinna stort om
              systemet slår in. Nackdelen är att du mer sällan kommer att få
              alla rätt.
            </p>

            <LinkButton to="/" className={styles.backButton}>
              {getText('jokerwiz-explain-home')}
            </LinkButton>
          </article>
        </Editorial>
      </ContentWrapper>
    </PageBody>
  );
};

export default withContentMenu(JokerwizInfoPage);
