import classnames from 'classnames';
import config from 'config';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import img from 'images/register-header.svg';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { getText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

import styles from './index.module.scss';

const SubscriptionsPage = ({ isLoggedIn }) => {
  const links = [
    config.registerUrl,
    getUrl.trmedia('buy?product_id=1'),
    getUrl.trmedia('user'),
  ];

  const getRow = (txt, spots) => (
    <div className={styles.row}>
      <div className={styles.col}>
        <div>{txt}</div>
      </div>

      <div className={styles.col}>
        {spots.map((spot, i) => (
          <div key={i} className={styles.item}>
            <div
              className={
                spot ? styles.check : classnames(styles.check, styles.inactive)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <PageMeta theme="article" title={getText('menu-register')} />
      <PageBody alignTo="menu" fullWidth>
        <header className={styles.headerWrapper}>
          <div className={styles.header}>{getText('register-header')}</div>
        </header>

        <div className={styles.table}>
          <div className={styles.row}>
            <div className={classnames(styles.col, styles.imgWrapper)}>
              <img alt={getText('register-header')} src={img} />
            </div>

            <div className={classnames(styles.col, styles.double)}>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={styles.planHeader}>
                    <span>{getText('register-plan-sport-header')}</span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.planHeader}>
                    <span>{getText('register-plan-bas-header')}</span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={classnames(styles.plan, styles.sport)}>
                    <span>{getText('register-plan-sport-name')}</span>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.plan}>
                    {getText('register-plan-bas-name')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <div>{getText('register-grid-monthly')}</div>
            </div>
            <div className={classnames(styles.col, styles.prices)}>
              <div className={classnames(styles.item, styles.txtCenter)}>
                {getText('register-plan-free')}
              </div>
              <div className={classnames(styles.item, styles.txtCenter)}>
                99 kr
              </div>
            </div>
          </div>

          {getRow(getText('register-grid-swedish-games'), [true, true])}
          {getRow(getText('register-grid-atg'), [false, true])}
          {getRow(getText('register-grid-reduce-abcd'), [true, true])}
          {getRow(getText('register-grid-reduce-number'), [false, true])}
          {getRow(getText('register-grid-factor'), [true, true])}
          {getRow(getText('register-grid-profit'), [false, true])}
          {getRow(getText('register-grid-trends'), [false, true])}
          {getRow(getText('register-grid-wiz'), [false, true])}
          {getRow(getText('register-grid-share'), [true, true])}
          {getRow(getText('register-grid-analyzing'), [true, true])}
          {getRow(getText('register-grid-autocorrection'), [true, true])}
          {getRow(getText('register-grid-race'), [false, true])}

          <div className={styles.row}>
            <div className={styles.col} />

            {isLoggedIn ? (
              <div className={styles.col}>
                <div className={styles.item}>
                  <a
                    className={classnames(styles.btnChoose, styles.fullWidth)}
                    href={links[2]}
                  >
                    {getText('menu-my-account')}
                  </a>
                </div>
              </div>
            ) : (
              <div className={styles.col}>
                <div className={styles.item}>
                  <a className={styles.btnChoose} href={links[0]}>
                    {getText('register-btn-choose')}
                  </a>
                </div>

                <div className={styles.item}>
                  <a className={styles.btnChoose} href={links[1]}>
                    {getText('register-btn-choose')}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageBody>
    </div>
  );
};

SubscriptionsPage.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default withContentMenu(SubscriptionsPage);
