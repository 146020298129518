import 'react-toggle/style.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';

import imgDefault from 'images/userpic.png';
import Edit from 'shared/components/icon/Edit';
import Image from 'shared/components/Image';
import ContentWrapper from 'shared/components/layout/ContentWrapper';
import Logo from 'shared/components/Logo';
import MenuItem from 'shared/components/menu/MenuItem';
import {
  categories,
  items as MenuItemTypes,
} from 'shared/constants/MenuConstants';
import { menuList } from 'shared/proptypes';
import { getText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

const Menu = ({
  alias,
  category,
  clickHandler,
  disabledIds,
  email,
  fullName,
  imageId,
  isMobileMenuVisible,
  items,
  liveParticipant,
  isLoggedIn,
  isSubscriber = false,
  mobileMenuRefsHandler,
  selectedId,
  toggleLiveParticipation,
  showDialog,
  toggleMobileMenuHandler,
}) => {
  const onPopupItemClick = (e, item) => {
    toggleMobileMenuHandler(e);
    clickHandler(e, item);
  };

  let className = 'menu';

  if (category) {
    className += ` menu--category-${category.toLowerCase()}`;
  }

  let linkedLogo = (
    <Link to="/">
      <Logo white />
    </Link>
  );

  if (process.env.REACT_APP_BUILD_TARGET !== 'prod') {
    const DevLogo = require('./DevPanelButton').default(linkedLogo);
    linkedLogo = <DevLogo />;
  }

  const onLiveParticipationChange = () => {
    if (alias || liveParticipant.resolved) {
      toggleLiveParticipation();
    } else {
      showDialog();
    }
  };

  return (
    <div className={className}>
      <ContentWrapper columnWidth="full" className="menu__inner">
        <div className="logo-wrapper">{linkedLogo}</div>

        <div className="menu-wrapper">
          <ul
            className={classnames('menu-list', {
              'has-auth': isLoggedIn,
              'no-auth': !isLoggedIn,
            })}
          >
            {items.map(item => (
              <MenuItem
                key={`main-${item.id}`}
                item={item}
                disabled={disabledIds.includes(item.id)}
                selected={selectedId === item.id}
                clickHandler={
                  item.id === 'burger' || item.id === 'ACCOUNT'
                    ? toggleMobileMenuHandler
                    : clickHandler
                }
                popupVisible={isMobileMenuVisible}
                refHandler={
                  item.id === 'burger' || item.id === 'ACCOUNT'
                    ? mobileMenuRefsHandler
                    : undefined
                }
              />
            ))}
          </ul>
        </div>

        <ul
          className={classnames('popup-menu-list', {
            active: isMobileMenuVisible,
            'has-auth': isLoggedIn,
            'no-auth': !isLoggedIn,
          })}
          ref={mobileMenuRefsHandler}
        >
          {isLoggedIn && (
            <li className="popup-profile-data">
              <div className="profile-data">
                <div className="texts">
                  <div className="profile-name">{fullName || email}</div>

                  <div className="subscription">
                    {getText('menu-popup-subscription')}:&nbsp;
                    {getText(
                      `menu-popup-subscription-${
                        isSubscriber ? 'paid' : 'normal'
                      }`,
                    )}
                  </div>
                </div>

                <div
                  className={classnames('profile-img', { default: !imageId })}
                >
                  <a
                    href={getUrl.trmedia('user')}
                    rel="noopener"
                    target="_blank"
                  >
                    {imageId ? (
                      <>
                        <Image
                          alt={email}
                          crop="thumb"
                          gravity="face"
                          imageId={imageId}
                          ratio={[1, 1]}
                          zoom="0.8"
                          width={45}
                        />
                        <Edit className="icon" />
                      </>
                    ) : (
                      <img src={imgDefault} alt={email} />
                    )}
                  </a>
                </div>
              </div>

              <div className="menu-profile-live">
                <b>{getText('menu-popup-live-header')}</b>

                <div className="alias">
                  {alias ? (
                    <>
                      <i>{getText('menu-popup-live-alias')}</i>: {alias}
                    </>
                  ) : (
                    getText('menu-popup-live-no-alias')
                  )}
                  &nbsp;
                  <a
                    href={getUrl.trmedia('user')}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {getText(`menu-popup-live-alias-${alias ? 'edit' : 'set'}`)}
                  </a>
                </div>

                <div className="play">
                  <Toggle
                    checked={
                      liveParticipant.loading
                        ? !liveParticipant.resolved
                        : liveParticipant.resolved
                    }
                    className="toggle"
                    disabled={liveParticipant.loading}
                    icons={false}
                    onChange={onLiveParticipationChange}
                  />
                  {getText('menu-popup-live-play')}
                </div>
              </div>
            </li>
          )}

          {items
            .filter(
              item =>
                item.id !== 'burger' && item.id !== MenuItemTypes.CALENDAR,
            )
            .map(item => (
              <MenuItem
                clickHandler={onPopupItemClick}
                disabled={disabledIds.includes(item.id)}
                item={item}
                key={`popup-${item.id}`}
                popupVisible={isMobileMenuVisible}
                selected={selectedId === item.id}
              />
            ))}
        </ul>
      </ContentWrapper>
    </div>
  );
};

Menu.propTypes = {
  alias: PropTypes.string,
  category: PropTypes.oneOf(Object.keys(categories)),
  clickHandler: PropTypes.func.isRequired,
  disabledIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string,
  fullName: PropTypes.string,
  imageId: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  isMobileMenuVisible: PropTypes.bool.isRequired,
  isSubscriber: PropTypes.bool,
  items: menuList.isRequired,
  mobileMenuRefsHandler: PropTypes.func.isRequired,
  selectedId: PropTypes.string,

  toggleLiveParticipation: PropTypes.func,
  showDialog: PropTypes.func,
  toggleMobileMenuHandler: PropTypes.func.isRequired,
  liveParticipant: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool,
    resolved: PropTypes.bool,
  }),
};
export default Menu;
