import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SiteMessages from 'shared/components/SiteMessages';
import { NAVIGATOR_OFFLINE } from 'shared/constants/MessageTypes';
import {
  dismissSiteMessageByClientId,
  dismissSiteMessageByServerId,
  dismissSiteMessageByType,
  fetchPromo,
} from 'shared/redux/actions';
import { ui, user } from 'shared/redux/selectors';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
  messages: ui.getUnseenSiteMessages(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismissSiteMessageByServerId,
      dismissSiteMessageByClientId,
      dismissSiteMessageByType,
      fetchPromo,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class SiteMessagesContainer extends Component {
    componentDidMount() {
      const { fetchPromo } = this.props;
      fetchPromo();
      window.addEventListener('online', this.onOnline);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.onOnline);
    }

    // Auto dismiss offline message when user comes back online again
    onOnline = () => {
      const { dismissSiteMessageByType } = this.props;
      dismissSiteMessageByType(NAVIGATOR_OFFLINE);
    };

    onCloseClick = message => {
      const { dismissSiteMessageByServerId, dismissSiteMessageByClientId } =
        this.props;
      const { id } = message;
      if (id) {
        dismissSiteMessageByServerId(message);
      } else {
        dismissSiteMessageByClientId(message);
      }
    };

    render() {
      const { messages, isLoggedIn } = this.props;

      const filteredMessages = isLoggedIn
        ? messages.filter(message => message.messageType !== 'promo')
        : messages;

      return (
        <SiteMessages messages={filteredMessages} onClose={this.onCloseClick} />
      );
    }
  },
);
