import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Editorial from 'shared/components/content/Editorial';
import ArrowForward from 'shared/components/icon/ArrowForward';
import ContentWrapper from 'shared/components/layout/ContentWrapper';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import { user } from 'shared/redux/selectors';

import withContentMenu from './withContentMenu';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
});

export default withContentMenu(
  connect(mapStateToProps)(
    class ContentPage extends Component {
      render() {
        const { isLoggedIn } = this.props;
        return (
          <PageBody>
            <PageMeta theme="article" title="Sidan kunde inte hittas" />
            <ContentWrapper columnWidth="narrow">
              <h1 className="article-header">OJDÅ!</h1>
              <Editorial>
                <h2>Sidan du letade efter hittades inte</h2>
                <ul className="link-list">
                  <li>
                    <Link to="/">
                      <ArrowForward /> Gå till startsidan
                    </Link>
                  </li>
                  {!isLoggedIn && (
                    <li>
                      <Link to="/login">
                        <ArrowForward /> Logga in
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/faq">
                      <ArrowForward /> Vanliga frågor och svar
                    </Link>
                  </li>
                  <li>
                    <Link to="/kontakt">
                      <ArrowForward /> Kontakta supporten
                    </Link>
                  </li>
                </ul>
              </Editorial>
            </ContentWrapper>
          </PageBody>
        );
      }
    },
  ),
);
