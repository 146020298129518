import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import { logoutUser } from 'shared/redux/actions';
import { user } from 'shared/redux/selectors';
import { getText } from 'shared/utils/Text';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logoutUser }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class LogoutPage extends Component {
    static propTypes = {};

    constructor(props) {
      super(props);

      this.state = {
        redirect: !props.isLoggedIn,
      };
    }

    componentDidMount() {
      this.props.logoutUser();
    }

    UNSAFE_componentWillReceiveProps({ isLoggedIn }) {
      if (!isLoggedIn) {
        this.setState({
          redirect: true,
        });
      }
    }

    render() {
      const { redirect } = this.state;

      if (redirect) {
        return <Redirect to="/" />;
      }

      // TODO - logging out content
      return (
        <PageBody>
          <PageMeta theme="article" title={getText('logout-logging-out')} />
        </PageBody>
      );
    }
  },
);
