import image from 'images/pil.svg';

const RegisterHeader = () => (
  <div className="login-container">
    <div className="register-header">
      Inte kund? Kolla här!
      <img src={image} alt="" />
    </div>
  </div>
);

export default RegisterHeader;
