import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { user } from 'shared/redux/selectors';

import ErrorBoundRoute from './ErrorBoundRoute';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
});

export default connect(mapStateToProps)(
  ({ component: Component, isLoggedIn, ...rest }) => (
    <ErrorBoundRoute
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: props.location },
            }}
          />
        )
      }
    />
  ),
);
