import { bool, string } from 'prop-types';

import { getText } from 'shared/utils/Text';

import Register from './Register';

const RegisterSubscription = ({
  dialog,
  productBodyText,
  productBodyExtra,
  buttonText,
}) => (
  <Register
    dialog={dialog}
    productHeader={getText('login-register-subscribe-header')}
    productBody={productBodyText || getText('login-register-subscribe-body')}
    productBodyExtra={productBodyExtra}
    buttonText={buttonText || getText('login-register-subscribe-button')}
    buttonUrl="/abonnemang"
  />
);

RegisterSubscription.propTypes = {
  dialog: bool,
  productBodyText: string,
  productBodyExtra: string,
  buttonText: string,
};

export default RegisterSubscription;
