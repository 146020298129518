import { useSelector } from 'react-redux';

import User from 'shared/components/icon/User';
import { user } from 'shared/redux/selectors';
import * as Text from 'shared/utils/Text';

const AccountItem = () => {
  const firstName = useSelector(state => user.getFirstname(state));
  const lastName = useSelector(state => user.getLastname(state));
  const hasName = [firstName, lastName].some(name => name.length > 0);
  return (
    <div className="flex flex--row-center">
      <User />
      {hasName ? (
        <span className="title-username">
          {firstName.length > 0 ? firstName : lastName}
        </span>
      ) : (
        <span className="title">{` ${Text.get('menu-account')}`}</span>
      )}
    </div>
  );
};

export default AccountItem;
