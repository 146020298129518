import { func, string } from 'prop-types';
import { Component, createElement, Fragment } from 'react';

import * as Text from 'shared/utils/Text';

import Button from '../button/Button';
import HelpButton from '../button/HelpButton';

export default class Confirmdialog extends Component {
  static propTypes = {
    closeHandler: func.isRequired,
    onConfirm: func,
    onReject: func,
    title: string.isRequired,
    details: string,
    positive: string,
    negative: string,
    url: string,
    helpHeader: string,
    helpBody: string,
  };

  onOkClick = () => {
    const { onConfirm, closeHandler } = this.props;
    onConfirm && onConfirm();
    closeHandler();
  };

  onCancelClick = () => {
    const { onReject, closeHandler } = this.props;
    onReject && onReject();
    closeHandler();
  };

  render() {
    const {
      title,
      details,
      positive = Text.get('dialog-confirm-confirm'),
      negative = Text.get('dialog-confirm-cancel'),
      url,
      helpHeader,
      helpBody,
    } = this.props;

    return (
      <Fragment>
        <h1
          className={
            helpHeader ? 'dialog-title dialog-title--with-help' : 'dialog-title'
          }
        >
          {title}
          {helpHeader && <HelpButton header={helpHeader} body={helpBody} />}
        </h1>
        <hr />
        {details && (
          <p
            className="details"
            dangerouslySetInnerHTML={{
              __html: details.replace(/\n/g, '<br/>'),
            }}
          />
        )}
        <hr />
        <ul className="button-list fgrid fgrid--1of2">
          <li className="fgrid-cell positive">
            {createElement(
              url != null ? 'a' : Button,
              {
                href: url,
                target: url != null ? '_blank' : undefined,
                onClick: this.onOkClick,
              },
              [
                <span key="c1" className="sprite dialog-confirm" />,
                <span key="c2" className="label">
                  {positive}
                </span>,
              ],
            )}
          </li>
          <li className="fgrid-cell negative">
            <Button onClick={this.onCancelClick}>
              <span className="sprite dialog-cancel" />
              <span className="label">{negative}</span>
            </Button>
          </li>
        </ul>
      </Fragment>
    );
  }
}
