import 'shared/utils/polyfills';
import 'current-input';
import './index.module.scss';

import config from 'config';
import { DFPSlotsProvider } from 'react-dfp';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from 'shared/components/ErrorBoundary';
import { UPDATE_AVAILABLE } from 'shared/constants/MessageTypes';
import VerifyApplicationAccessible from 'shared/containers/VerifyApplicationAccessible';
import { addSiteMessage } from 'shared/redux/actions';
import { configureStore } from 'shared/redux/store';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.info(`v.${config.appVersion}`);

const store = configureStore();

const AdNetworkProvider = ({ children }) =>
  process.env.REACT_APP_ADS_ENABLED === 'true' ? (
    <DFPSlotsProvider dfpNetworkId="122244050">{children}</DFPSlotsProvider>
  ) : (
    children
  );

render(
  <ErrorBoundary>
    <Router>
      <Provider store={store}>
        <AdNetworkProvider>
          <VerifyApplicationAccessible>
            <App />
          </VerifyApplicationAccessible>
        </AdNetworkProvider>
      </Provider>
    </Router>
  </ErrorBoundary>,
  document.querySelector('#app'),
);

serviceWorkerRegistration.register({
  onUpdate: swRegistration =>
    store.dispatch(addSiteMessage(UPDATE_AVAILABLE, { swRegistration })),
});
