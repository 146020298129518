import uniqueId from 'lodash/uniqueId';

import * as dialogTypes from 'shared/constants/DialogTypes';
import * as types from 'shared/redux/actionTypes';

export const showDialog = (type, props) => ({
  type: types.SHOW_DIALOG,
  payload: {
    type,
    props,
  },
  meta: {
    key: uniqueId('dialog_'),
  },
});

export const showConfirmDialog = props =>
  showDialog(dialogTypes.CONFIRM, props);

export const showAlertDialog = props => showDialog(dialogTypes.ALERT, props);

export const showHelpDialog = (title, body) =>
  showDialog(dialogTypes.HELP, { title, body });

export const showAtgReportDialog = () => showDialog(dialogTypes.ATG_REPORT);

export const showAtgTemplateDialog = props =>
  showDialog(dialogTypes.ATG_TEMPLATE, props);

export const showAtgArchiveDialog = () => showDialog(dialogTypes.ATG_ARCHIVE);

export const showSportArchiveDialog = () =>
  showDialog(dialogTypes.SPORT_ARCHIVE);

export const showAtgShareDialog = () => showDialog(dialogTypes.ATG_SHARE);

export const showSportShareDialog = betId =>
  showDialog(dialogTypes.SPORT_SHARE, { betId });

export const showSportExportDialog = betId =>
  showDialog(dialogTypes.SPORT_EXPORT, { betId });

export const showSportPublishDialog = betId =>
  showDialog(dialogTypes.SPORT_PUBLISH, { betId });

export const showCouponsDialog = () => showDialog(dialogTypes.ATG_COUPONS);

export const showJokerWizDialog = betParams =>
  showDialog(dialogTypes.JOKERWIZ_DIALOG, { betParams });

export const showLockedAlertDialog = isShared =>
  showDialog(dialogTypes.LOCKED_ALERT, { isShared });

export const showGuaranteeAlertDialog = () =>
  showDialog(dialogTypes.GUARANTEE_ALERT);

export const showErrorDialog = ({ message, details, logNumber }) =>
  showDialog(dialogTypes.ERROR, { message, details, logNumber });

export const showLiveNoAliasDialog = () =>
  showDialog(dialogTypes.LIVE_NO_ALIAS_DIALOG);

export const showSubscriptionRequiredDialog = (
  productBodyText,
  productBodyExtra,
  buttonText,
) =>
  showDialog(dialogTypes.SUBSCRIPTION_REQUIRED, {
    productBodyText,
    productBodyExtra,
    buttonText,
  });

export const showAtgSettingsDialog = () => showDialog(dialogTypes.ATG_SETTINGS);

export const toggleDialogLocked = (key, locked) => ({
  type: types.TOGGLE_LOCK_DIALOG,
  payload: { key, locked },
});

export const popDialog = key => ({
  type: types.POP_DIALOG,
  payload: key,
});

export const popDialogByType = dialogType => ({
  type: types.POP_DIALOG_BY_TYPE,
  payload: dialogType,
});
