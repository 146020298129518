import PropTypes from 'prop-types';

import logo from 'images/jokersystemet_logotyp.svg';
import logoWhite from 'images/jokersystemet_white_logotyp.svg';
import { getText } from 'shared/utils/Text';

const Logo = ({ white = false, className }) => (
  <img
    src={white ? logoWhite : logo}
    className={className}
    alt={getText('title-app')}
  />
);

Logo.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
};

export default Logo;
