import PropTypes from 'prop-types';

import Button from 'shared/components/button/Button';
import Logo from 'shared/components/Logo';

import styles from './index.module.scss';

const headings = {
  maintenance: 'Jokersystemet är nere för underhåll',
  offline: 'Inget internet',
};

const content = {
  maintenance: (
    <>
      Just nu uppdaterar vi Jokersystemet och det är därför inte tillgängligt. I
      regel tar det några få minuter och Joker laddas automatiskt när den är
      tillgänglig. Om så inte sker{' '}
      <Button className="text-button" onClick={() => window.location.reload()}>
        klicka här
      </Button>
      .
    </>
  ),
  offline: (
    <>
      Vänligen kontrollera att du har en fungerande internetuppkoppling och{' '}
      <Button className="text-button" onClick={() => window.location.reload()}>
        försök igen
      </Button>
      .
    </>
  ),
};

const ApplicationInaccessible = ({ reason }) => (
  <div className={styles.root}>
    <Logo className={styles.img} />
    <h1 className={styles.h1}>{headings[reason]}</h1>
    <p className={styles.p}>{content[reason]}</p>
  </div>
);

ApplicationInaccessible.propTypes = {
  reason: PropTypes.oneOf(['maintenance', 'offline']).isRequired,
};

export default ApplicationInaccessible;
