import classnames from 'classnames';
import { bool, func } from 'prop-types';

import Button from '../button/Button';

const BurgerButton = ({ active = false, onClick }) => (
  <Button
    className={classnames('burger-button', {
      'burger-button--active': active,
    })}
    onClick={onClick}
  >
    <span className="line" />
    <span className="line" />
    <span className="line" />
    <span className="line" />
  </Button>
);

BurgerButton.propTypes = {
  active: bool,
  onClick: func.isRequired,
};

export default BurgerButton;
