import { Component } from 'react';
import { connect } from 'react-redux';

import AlertDialog from 'shared/components/dialog/AlertDialog';
import ConfirmDialog from 'shared/components/dialog/ConfirmDialog';
import ErrorDialog from 'shared/components/dialog/ErrorDialog';
import GuaranteeAlertDialog from 'shared/components/dialog/GuaranteeAlertDialog';
import HelpDialog from 'shared/components/dialog/HelpDialog';
import LiveNoAliasDialog from 'shared/components/dialog/LiveNoAliasDialog';
import LockedAlertDialog from 'shared/components/dialog/LockedAlertDialog';
import SettingsDialog from 'shared/components/dialog/SettingsDialog';
import SubscriptionRequiredDialog from 'shared/components/dialog/SubscriptionRequiredDialog';
import * as DialogTypes from 'shared/constants/DialogTypes';
import { dialogs } from 'shared/redux/selectors';
import { createPortal } from 'shared/utils/dialogUtils';

const mapStateToProps = state => ({
  dialogs: dialogs.getDialogs(state),
});

export default connect(mapStateToProps)(
  class Dialogs extends Component {
    render() {
      const { dialogs } = this.props;

      return dialogs.map(({ type, key, props }) => {
        switch (type) {
          case DialogTypes.ALERT:
            return createPortal(AlertDialog, props, key, 'alert');
          case DialogTypes.ERROR:
            return createPortal(ErrorDialog, props, key, 'error');
          case DialogTypes.CONFIRM:
            return createPortal(ConfirmDialog, props, key, 'confirm');
          case DialogTypes.LOCKED_ALERT:
            return createPortal(LockedAlertDialog, props, key, 'alert');
          case DialogTypes.GUARANTEE_ALERT:
            return createPortal(GuaranteeAlertDialog, props, key, 'alert');
          case DialogTypes.HELP:
            return createPortal(HelpDialog, props, key, 'dialog-help');
          case DialogTypes.LIVE_NO_ALIAS_DIALOG:
            return createPortal(LiveNoAliasDialog, props, key, 'live-no-alias');
          case DialogTypes.SUBSCRIPTION_REQUIRED:
            return createPortal(
              SubscriptionRequiredDialog,
              props,
              key,
              'subscription-required',
            );
          case DialogTypes.ATG_SETTINGS:
            return createPortal(SettingsDialog, props, key, 'settings');
          default:
            return null;
        }
      });
    }
  },
);
