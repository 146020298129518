import { node, string } from 'prop-types';
import { Component } from 'react';

import Editorial from 'shared/components/content/Editorial';
import HTMLContent from 'shared/components/HTMLContent';
import ContentWrapper from 'shared/components/layout/ContentWrapper';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';

import withCMSPage from './withCMSPage';

export default withCMSPage(
  class ContentPage extends Component {
    static propTypes = {
      pageTitle: string.isRequired,
      pageBody: string,
      children: node,
    };

    render() {
      const { pageTitle, pageBody, children } = this.props;

      return (
        <PageBody>
          <PageMeta theme="article" title={pageTitle} />
          <ContentWrapper columnWidth="narrow">
            <h1 className="article-header">{pageTitle}</h1>
            {pageBody && (
              <Editorial>
                <HTMLContent content={pageBody} />
              </Editorial>
            )}
          </ContentWrapper>
          {children}
        </PageBody>
      );
    }
  },
);
