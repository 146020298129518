import { lazy, Suspense } from 'react';

const NewsComponent = lazy(() => import(/* webpackChunkName: "news" */ 'news'));

const AsyncNewsApp = () => (
  <Suspense fallback={<span />}>
    <NewsComponent />
  </Suspense>
);

export default AsyncNewsApp;
