import { bool, func, number, shape, string } from 'prop-types';

import Button from '../button/Button';
import Editorial from '../content/Editorial';

const playerProps = ['hl=sv', 'rel=0', 'showinfo=0', 'fs=1', 'autoplay=1'].join(
  '&',
);

const InstructionsItem = ({
  videoId,
  thumbnail,
  title,
  description,
  playing,
  onPlayClick,
}) => (
  <li>
    <div className="player">
      {playing ? (
        <iframe
          type="text/html"
          title="Youtube"
          src={`https://www.youtube.com/embed/${videoId}?${playerProps}`}
          frameBorder="0"
          allowFullScreen="allowfullscreen"
        />
      ) : (
        <Button onClick={onPlayClick} data-id={videoId}>
          <img
            src={thumbnail.url}
            alt={title}
            width={thumbnail.width}
            height={thumbnail.height}
          />
        </Button>
      )}
    </div>
    <Editorial>
      <h4>{title}</h4>
      <p>{description}</p>
    </Editorial>
  </li>
);

InstructionsItem.propTypes = {
  videoId: string.isRequired,
  thumbnail: shape({
    url: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }),
  title: string.isRequired,
  description: string.isRequired,
  playing: bool.isRequired,
  onPlayClick: func.isRequired,
};

export default InstructionsItem;
